import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { useLocation } from '@reach/router'
import { navbar } from './navbar.module.scss';
import MainMenu from './mainMenu.js';

export default function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation(); // Get the current url route location

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  
  useEffect(() => {
    // Close the menu whenever the route changes
    setMenuOpen(false);
  }, [location.pathname]); // Dependency array with location.pathname

  return (
    <div className={ navbar }>
      <nav className='navbar__container'>
        <h2 className='navbar__logo'>
          <Link to='/' title='Accueil'>
            <StaticImage 
              src='../images/Cactus-kombucha-logo-light.png' 
              alt='Cactus Kombucha' 
              placeholder='none'
            />
          </Link>
        </h2>
        <div className={ menuOpen ? 'navbar__menu open' : 'navbar__menu closed'}>
          <MainMenu context='navbar' />
        </div>
        <button className={ menuOpen ? 'navbar__toggle open' : 'navbar__toggle closed'} aria-label="Toggle navigation" onClick={ toggleMenu }>
          <span className="hamburgerBar"></span>
          <span className="hamburgerBar"></span>
          <span className="hamburgerBar"></span>
        </button>
      </nav>
    </div>
  );
};
