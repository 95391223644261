import React from "react"
import { Link } from 'gatsby'
import Menu from '@mui/joy/Menu'
import MenuButton from '@mui/joy/MenuButton'
import Dropdown from '@mui/joy/Dropdown'
import '../styles/specifics.scss'

export default function MainMenu({ context }) {

  const links = [
    // { to: "/#kombucha", text: "Les kombuchas", isProduct: true },
    // { to: "/cueillettes", text: "Les cueillettes", isProduct: true },
    { to: "/#kombucha", text: "Les kombuchas", isProduct: false },
    { to: "/points-vente", text: "Points de vente", isProduct: false },
    { to: "/a-propos", text: "À propos", isProduct: false },
    { to: "/faq", text: "F.A.Q.", isProduct: false },
    { to: "#contact", text: "Contact", isProduct: false },
  ];
  
  const hasProductSubItems = links.some(link => link.isProduct === true);

  return (
    <>
      {context === 'navbar' ? (
        <>
          { hasProductSubItems === true && (
            <Dropdown>
              <MenuButton className='menuLink'>
                Nos produits
              </MenuButton>
              <Menu>
                {links.filter(link => link.isProduct).map((link) => (
                  <Link key={link.to} to={link.to}>{link.text}</Link>
                ))}
              </Menu>
            </Dropdown>
          )}
          
          {links.filter(link => !link.isProduct).map((link) => (
            <Link key={link.to} to={link.to}>{link.text}</Link>
          ))}
        </>
      ) : (
        links.map((link) => (
          <Link key={link.to} to={link.to}>{link.text}</Link>
        ))
      )}
    </>
  );
}
