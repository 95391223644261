import React from "react";
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby'
import { footer } from './footer.module.scss';
import MainMenu from './mainMenu.js';

export default function Footer() {
  return (
    <div className={footer}>
      <section id='contact'>
        <StaticImage 
          src='../images/Cactus-kombucha-logo-dark.png' 
          alt='Cactus Kombucha' 
          placeholder='none'
          width={200} 
          className='logo'
        />
        <address>
          <p>Cactus Kombucha<br/>
            5000 Iberville, suite 246<br/>
            Montréal, Qc., H2H 2S6
          </p>
          <p>
            438.496.9045<br/> 
            <a href='mailto:info@cactuskombucha.ca' target='_blank' rel='noopener noreferrer'>info@cactuskombucha.ca</a>
          </p>
        </address>
      </section>
      
      <section> 
        <div className='socials'>
          <a href='https://www.facebook.com/CactusKombucha' target='_blank' title='Facebook' rel='noopener noreferrer'>FB</a>
          <a href='https://www.instagram.com/cactus_kombucha/' target='_blank' title='Instagram' rel='noopener noreferrer'>IG</a>
          <StaticImage 
            src='../images/cactus/cactus-rose.png' 
            alt='Illustration de cactus' 
            placeholder='none'
            width={150}
          />
        </div>
        
        <div className='h-flex' style={{ flexWrap:'nowrap' }}>
          <StaticImage
            src='../images/aliment-prepare-quebec.png'
            title='Certification Aliment préparé au Québec'
            width='80'
            objectFit='contain'
          />
          <p>Certifié <br/><a href='https://www.alimentsduquebec.com/fr/produits-dici/produits-certifies/kombucha-rose-framboise' target='_blank'   rel='noopener noreferrer'>Aliments faits au Québec</a></p>
        </div>
      </section>
      
      <nav>
        <MainMenu />
        <Link 
          to='/confidentialite' 
          style={{'textTransform': 'none'}}
        >
          Politique de confidentialité
        </Link>
      </nav>
      
      <div>
        <div className='commercants'>
          <h2 style={{'marginBottom': '0'}}>POUR LES COMMERÇANTS&nbsp;!</h2>
          <p style={{'textTransform': 'uppercase'}}>
            Commandez nos produits en ligne <br/>
            sur 
            <a 
              href='https://info.pivohub.com/fr/' 
              target='_blank' 
              rel='noopener noreferrer'
            >
              PivoHub
            </a> 
            ou sur 
            <a 
              href='https://www.arrivage.com/' 
              target='_blank' 
              rel='noopener noreferrer'
            >
              Arrivage
            </a>
          </p>
          <p>SALE SHEET en PDF disponible sur demande</p>
        </div>
        
        <p>
          Crédit photos professionnelles&nbsp;: 
          <a 
            href='https://nathaliephotographie.ca/' 
            target='_blank' 
            rel='noopener noreferrer'
          >
            Nathalie Choquette
          </a> <br/>
          Conception et développement web&nbsp;: 
          <a 
            href='https://myriadeweb.com/' 
            target='_blank' 
            rel='noopener noreferrer'
          >
            Myriade Web
          </a> 
        </p>
      </div>
      
    </div>
  )
}