import React from 'react'
import { Script } from 'gatsby'
import '../styles/globals.scss'
import { pageLayout } from './pageLayout.module.scss'
import Navbar from '../components/navbar'
import Footer from '../components/footer'

export default function PageLayout({ children }) {
  return (
    <div className={ pageLayout }>
      <header>
       <Navbar />
      </header>
      <main>
        {children}
      </main>
      <footer>
        <Footer />
      </footer>
      <Script id="axeptio">{`
        window.axeptioSettings = {
          clientId: "65cac9ed4fa40e5d2a3ad2a7",
          cookiesVersion: "cactus kombucha-fr-EU",
        };
         
        (function(d, s) {
          var t = d.getElementsByTagName(s)[0], e = d.createElement(s);
          e.async = true; e.src = "//static.axept.io/sdk-slim.js";
          t.parentNode.insertBefore(e, t);
        })(document, "script");
      `}</Script>
    </div>
  )
}